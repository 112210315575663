import React from 'react';
import Link from 'next/link';
import {
  Flex,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Heading,
  useMediaQuery,
} from '@chakra-ui/react';
import moment from 'moment';

const Footer = () => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

  return (
    <Flex
      as="footer"
      direction="column"
      backgroundColor="#0b0b0b"
      fontSize={14}
    >
      <Flex
        as="nav"
        direction={isLargerThan1024 ? 'row' : 'column'}
        justifyContent="space-around"
        p={isLargerThan1024 ? 20 : 10}
        pt={0}
      >
        {/* About Us Section */}
        <Box maxW={300} pt={20}>
          <Heading as="h3" size="md" color="whiteAlpha.900">
            About Us
          </Heading>
          <Text color={'whiteAlpha.900'}>
            Vert Executive is a driving force in Executive Chauffeur Service and
            Sustainable Transportation, merging premium safety, LuxuryTravel,
            and a commitment to Carbon Reduction. Our exclusive fleet of
            high-end electric and hybrid vehicles, operated by professional,
            experienced chauffeurs, sets a new standard for Corporate Travel.
            Guided by an eco-conscious mission, Vert Executive is dedicated to
            inspiring change across Green Mobility solutions, leading the way
            with a Eco Friendly Fleet that reflects our unwavering focus on
            sustainability. Together, we can pave the way for cleaner, brighter
            travel solutions for generations to come with Vert Executive—where
            luxury meets responsibility.
          </Text>
        </Box>

        {/* Company Links Section */}
        <Box pt={20}>
          <Heading as="h3" size="md" color="whiteAlpha.900">
            Company
          </Heading>
          <UnorderedList
            color="whiteAlpha.900"
            listStyleType="none"
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </ListItem>
            <ListItem>
              <Link href="/terms-and-conditions">Terms and Conditions</Link>
            </ListItem>
            <ListItem>
              <Link href="/cancellation-fee-policy-rider">
                Cancellation Fee Policy
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://vert.eyesafe.london/#/customer/eyesafe">
                Corporate Events & Airport Services
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://zfrmz.com/CLgmEDkJtdeJF0Qt2afz">
                Drive with Vert
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://zfrmz.com/yRp3Av4RakgvYAzYDasr">
                Vert for Business
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://vert-london.apidocumentation.com/">
                API Documentation
              </Link>
            </ListItem>
          </UnorderedList>
        </Box>

        {/* Quick Links Section */}
        <Box pt={20}>
          <Heading as="h3" size="md" color="whiteAlpha.900">
            Quick Links
          </Heading>
          <UnorderedList
            color="whiteAlpha.900"
            listStyleType="none"
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="/">Home</Link>
            </ListItem>
            <ListItem>
              <Link href="/blog">Blog</Link>
            </ListItem>
            <ListItem>
              <Link href="/faq">FAQ</Link>
            </ListItem>
            <ListItem>
              <Link href="/chauffeur">Chauffeur</Link>
            </ListItem>
            <ListItem>
              <Link href="/affiliate">Affiliate</Link>
            </ListItem>
            <ListItem>
              <Link href="/advertise">Advertise</Link>
            </ListItem>
            <ListItem>
              <Link href="/contact">Contact</Link>
            </ListItem>
            <ListItem>
              <Link href="/locations">Locations</Link>
            </ListItem>
          </UnorderedList>
        </Box>

        {/* Social Media & Location Links */}
        <Box pt={20}>
          <Heading as="h3" size="md" color="whiteAlpha.900">
            Social Pages
          </Heading>
          <UnorderedList
            color="whiteAlpha.900"
            listStyleType="none"
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="https://www.facebook.com/GoGreenGoVert">
                Facebook
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://www.instagram.com/vert_london1">
                Instagram
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://twitter.com/Vert_LDN">Twitter</Link>
            </ListItem>
            <ListItem>
              <Link href="https://www.linkedin.com/company/vert-london">
                LinkedIn
              </Link>
            </ListItem>
          </UnorderedList>

          <Heading as="h3" size="md" color="whiteAlpha.900" mt={6}>
            Locations
          </Heading>
          <UnorderedList
            color="whiteAlpha.900"
            listStyleType="none"
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="?location=London">Chauffeur Service London</Link>
            </ListItem>
            <ListItem>
              <Link href="?location=Essex">Chauffeur Service Essex</Link>
            </ListItem>
          </UnorderedList>

          <Heading as="h3" size="md" color="whiteAlpha.900" mt={6}>
            Airport Chauffeur Service
          </Heading>
          <UnorderedList
            color="whiteAlpha.900"
            listStyleType="none"
            m={0}
            p={0}
            spacing={2}
          >
            <ListItem>
              <Link href="?location=HeathrowAirport">Heathrow Airport</Link>
            </ListItem>
            <ListItem>
              <Link href="?location=LondonCityAirport">
                London City Airport
              </Link>
            </ListItem>
            <ListItem>
              <Link href="?location=StanstedAirport">Stansted Airport</Link>
            </ListItem>
            <ListItem>
              <Link href="?location=GatwickAirport">Gatwick Airport</Link>
            </ListItem>
            <ListItem>
              <Link href="?location=SouthendAirport">Southend Airport</Link>
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>

      {/* Footer Copyright */}
      <Box textAlign="center" py={4}>
        <Text color="whiteAlpha.900">
          &copy;{moment().format('YYYY')} Vert London. TfL Licence 01042901
        </Text>
      </Box>
    </Flex>
  );
};

export default Footer;
